@import url("https://fonts.googleapis.com/css?family=Inconsolata&display=swap");
/* make the customizations */
$theme-colors: (
  "primary": #008080,
  "secondary": #d5986f,
);
$primary: #008080;
$secondary: #d5986f;
@import "~bootstrap/scss/bootstrap";

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Inconsolata", monospace;
  cursor: pointer;
  outline: 0;
  scroll-behavior: smooth;
}

::-moz-selection {
  background: $secondary;
}

::selection {
  background: $secondary;
}

body,
html {
  margin: 0;
  padding: 0;
  background-color: #fff;
  color: #444452;
  font-family: Apercu, Source Sans Pro, Helvetica, Arial, sans-serif;
  line-height: 1.5;
}

body a,
html a {
  text-decoration: none;
  font-weight: 700;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

body a:hover,
html a:hover {
  color: $secondary;
}

body ul,
html ul {
  list-style: none;
  padding: 0;
}

body strong,
html strong {
  font-weight: 700;
  color: #36363c;
}

body p,
html p {
  margin-bottom: 0;
}

.list-group-item {
  padding: 0;
}

.h1 {
  font-size: 2rem;
}

main.night,
html.night {
  background-color: #131417;
  color: #ffffff;
}

.night .text-primary {
  color: #c87137 !important;
}

.night .rb-badge {
  color: white;
  border-color: #c87137;
}

.sun,
.moon {
  color: #008080 !important;
}

main.night ::-moz-selection,
html.night ::-moz-selection {
  color: #ffffff;
  background: $primary;
}

main.night ::selection,
html.night ::selection {
  color: #ffffff;
  background: $primary;
}

.rb-hr {
  border-top: 3px solid $primary;
  width: 100%;
}

.rb-badge {
  font-size: 0.9rem;
  font-weight: normal;
}

//Image Shaker Start
.shake:hover {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shaker 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shaker {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

//Image Shaker End
//Arrow Animation Start
.arrow,
.arrow:before {
  position: absolute;
  left: 50%;
}

.arrow {
  width: 40px;
  height: 40px;
  top: 90%;
  margin: -20px 0 0 -20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  border-left: none;
  border-top: none;
  border-right: 2px $primary solid;
  border-bottom: 2px $primary solid;
}

.arrow:before {
  content: "";
  width: 20px;
  height: 20px;
  top: 50%;
  margin: -10px 0 0 -10px;
  border-left: none;
  border-top: none;
  border-right: 2px $primary solid;
  border-bottom: 2px $primary solid;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: arrow;
}

@keyframes arrow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(-10px, -10px);
  }
}

//Arrow Animation End
//Scroll Bar Start
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $secondary;
}

//Scroll Bar End
//Tab Navigation Start
.nav-tabs {
  border-bottom: 2px solid $secondary;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: none;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border: 2px solid $secondary;
  border-bottom: none;
  color: $secondary;
  background: none;
}

.nav-tabs .nav-link {
  border: none;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border: 2px solid $primary;
  border-bottom: none;
}

//Tab Navigation End

.l-title {
  border-bottom: 2px solid $secondary;
}

.l-work {
  border-right: 5px solid $secondary;
}

.r-work {
  border-left: 5px solid $secondary;
}

.r-subtitle {
  border-bottom: 2px solid $primary;
}

/* Hovereffect CSS Start */
.hovereffect {
  width: 100%;
  height: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  cursor: default;
}

.hovereffect img {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  display: block;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.hovereffect:hover img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

/* Hovereffect CSS End */

/* Inout Animation Effect Start*/
.inout-animation {
  animation: inoutanim 0.5s;
  backface-visibility: visible;
  -webkit-backface-visibility: visible;
}

@keyframes inoutanim {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

/* Inout Animation Effect End*/
